<template>
    <div class="wish-button">
        <md-button @click="toggleWishlist()" class="circular-progress-button md-warn" v-if="wished"> <md-icon>favorite</md-icon>&nbsp;Wished </md-button>
        <md-button @click="toggleWishlist()" class="md-raised circular-progress-button md-primary" v-if="!wished">
            <md-spinner :md-size="20" class="md-accent app-spinner" color="#fff" md-indeterminate v-if="!!loading"></md-spinner>
            <md-icon v-else>favorite</md-icon>&nbsp;Add To Wishlist
        </md-button>
    </div>
</template>

<script>
import { reviewSettings } from "@/config";
import { mapGetters, mapMutations } from "vuex";
export default {
    props: ["product", "variant"],
    components: {},
    data() {
        return {
            wished: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({ user: "user/user" }),
    },
    methods: {
        async checkWish() {
            this.wished = false;
            let result = await this.$store.dispatch("crud/get", {
                api: "wishlists/product/" + this.product._id + "/" + this.variant._id,
                noErr: true,
            });
            this.wished = result.length ? true : false;
        },
        toggleWishlist() {
            if (!this.user || !this.user.email) {
                this.$router.push("/account/login");
            } else {
                this.save();
            }
        },
        async save() {
            let result = await this.$store.dispatch("crud/post", {
                api: "wishlists",
                data: { product: this.product._id, variant: this.variant._id },
            });
            if (result.msg !== "deleted") {
                this.$store.commit("setMessage", "Added to your wishlist");
                this.wished = true;
            } else {
                this.$store.commit("setMessage", "Removed from your wishlist");
                this.wished = false;
            }
        },
    },
    created() {
        if (this.user) {
            this.checkWish();
        }
        this.eventHub.$on("loginSuccess", () => {
            console.info("loginSuccess fired");
            this.checkWish();
        });
    },
};
</script>