<template>
    <div class="qa-product">
        <!-- show qa list-->
        <md-subheader>Questions & Answers</md-subheader>
        <md-list>
            <md-list-item :key="item._id" v-for="item in showList">
                <div>
                    <b>Q:</b>
                    <span>{{item.question}}</span>
                </div>
                <md-list-expand>
                    <div class="qa-expand">
                        <md-list>
                            <template v-for="(reply,ix) in (item.showAllReply?item.dialogs:item.dialogs.slice(0,5))">
                                <md-list-item :key="ix" class v-if="ix!=0">
                                    <div class="reply-item">
                                        <b>A:</b>
                                        {{reply.content}}
                                        <p class="reply-created-time">{{reply.createdAt |date}}</p>
                                    </div>
                                </md-list-item>
                            </template>
                            <md-list-item @click="item.showAllReply=!item.showAllReply" v-if="!item.showAllReply&&item.dialogs.length>5">
                                <span class="show-all-reply">show all</span>
                            </md-list-item>
                        </md-list>
                    </div>
                </md-list-expand>
            </md-list-item>
        </md-list>

        <!-- create qa -->
        <md-button @click="openQAQuestionModal" class="md-primary md-raised">Ask A Question</md-button>
        <q-a-question-modal :productId="id" @success="fetchQA" ref="qaQuestionModal" title="Ask A Question"></q-a-question-modal>
    </div>
</template>
<script>
import QAQuestionModal from "@/components/QA/QAQuestionModal.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
    props: ["id"],
    components: {
        QAQuestionModal
    },
    data: function() {
        return {
            // qList: [],
            showList: [],
            // isShowAll: false,
            showText: "Show QA"
        };
    },
    computed: {
        ...mapGetters({ user: "user/user" })
    },
    watch: {
        // isShowAll: function(newValue) {
        //     if (newValue) {
        //         this.showList = this.qList.slice(0);
        //     } else {
        //         this.showList = this.qList.slice(0, 5);
        //     }
        // }
    },
    methods: {
        async fetchQA() {
            let result = await this.$store.dispatch("crud/get", {
                api: `/api/qas`,
                params: {
                    product_id: this.id,
                    private: false,
                    sort: "-createdAt"
                }
            });
            let qList = result.data.map(n => ({ ...n, showAllReply: false }));
            this.showList = qList.splice(0, 5);
        },
        openQAQuestionModal() {
            this.$refs["qaQuestionModal"].open();
        }
    },
    created() {
        this.fetchQA();
    },
    beforeDestroy() {}
};
</script>
<style lang='less'>
.qa-product {
    max-width: 1000px;
    .qa-expand {
        .md-list {
            .md-list-item-container {
                padding-left: 35px;
            }
        }
        .reply-item {
            margin: 10px 0;
            width: 100%;
            b {
                color: #5e4e38;
            }
            .reply-created-time {
                text-align: right;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.54);
            }
        }
        .show-all-reply {
            color: #b67a22;
        }
    }

    .md-list {
        padding: 0;
        .md-list-item-container.md-button {
            white-space: inherit;
        }
    }
}
</style>

