<template>
    <div class="reviews">
        <div class="rows" v-if="reviewSettings.enabled && reviews.length === 0">
            <md-button @click="reviewForm()" class="md-raised md-primary"> <md-icon>star</md-icon>Be the first to review this item </md-button>
        </div>
        <div class="reviews-con">
            <div class="reviews-header sb" v-if="reviews.length > 0 && reviewSettings.enabled">
                <div class="total-rating">
                    <div class="total">
                        {{ rating.avg }}
                        <md-icon>star</md-icon>
                    </div>
                    <div>{{ rating.count }} Ratings</div>
                    <div>{{ reviewCount }} Reviews</div>
                </div>
                <div class="rating-signal column flex">
                    <div class="sb vcenter">
                        <div>
                            5
                            <md-icon style="color: green">star</md-icon>
                        </div>
                        <div class="flex">
                            <md-progress :md-progress="(rating.r5 * 100) / rating.count" md-mode="determinate"></md-progress>
                        </div>
                        <div class="ml10">{{ rating.r5 }}</div>
                    </div>
                    <div class="sb vcenter">
                        <div>
                            4
                            <md-icon style="color: rgb(33, 150, 243)">star</md-icon>
                        </div>
                        <div class="flex">
                            <md-progress :md-progress="(rating.r4 * 100) / rating.count" class="md-primary" md-mode="determinate"></md-progress>
                        </div>
                        <div class="ml10">{{ rating.r4 }}</div>
                    </div>
                    <div class="sb vcenter">
                        <div>
                            3
                            <md-icon style="color: orange">star</md-icon>
                        </div>
                        <div class="flex">
                            <md-progress :md-progress="(rating.r3 * 100) / rating.count" md-mode="determinate"></md-progress>
                        </div>
                        <div class="ml10">{{ rating.r3 }}</div>
                    </div>
                    <div class="sb vcenter">
                        <div>
                            2
                            <md-icon style="color: rgb(255, 87, 34)">star</md-icon>
                        </div>
                        <div class="flex">
                            <md-progress :md-progress="(rating.r2 * 100) / rating.count" class="md-accent" md-mode="determinate"></md-progress>
                        </div>
                        <div class="ml10">{{ rating.r2 }}</div>
                    </div>
                    <div class="sb vcenter">
                        <div>
                            1
                            <md-icon style="color: rgb(255, 87, 34)">star</md-icon>
                        </div>
                        <div class="flex">
                            <md-progress :md-progress="(rating.r1 * 100) / rating.count" class="md-warn" md-mode="determinate"></md-progress>
                        </div>
                        <div class="ml10">{{ rating.r1 }}</div>
                    </div>
                </div>
                <div class="vcenter" v-if="reviewSettings.enabled && reviews.length > 0">
                    <md-button @click="reviewForm()" class="md-raised md-primary"> <md-icon>star</md-icon>Rate & Review </md-button>
                </div>
            </div>
            <md-card :key="ix" class="mb10" v-for="(review, ix) in reviews">
                <!-- <md-button @click="deleteReview(review)" class="close md-icon-button" v-if="hasRole('manager')">
                    <md-icon style="fill: #aaa">delete</md-icon>
                </md-button> -->
                <md-card-content>
                    <div class="left">
                        <div>
                            <div class="rating-button" v-if="review.rating">
                                {{ review.rating }}
                                <md-icon class="reviewRating">star</md-icon>
                            </div>
                            <div>{{ review.message }}</div>
                            <small v-if="!review.active">[under review]</small>
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions>
                    <div class="mute left vcenter" style="align-items:center">
                        <md-icon class="footer-icons">person</md-icon>
                        &nbsp;{{ review.user_name }}&nbsp;&nbsp;
                        <md-icon class="footer-icons" style="margin-left:10px">access_time</md-icon>
                        &nbsp;{{ review.createdAt | date }}
                    </div>
                </md-card-actions>
                <template v-for="(r, iix) in review.replys">
                    <md-card :key="iix" v-if="r.active || hasRole('manager')">
                        <md-card-content>
                            <div class="left">
                                <div>{{ r.message }}</div>
                                <small v-if="!r.active">[under review]</small>
                            </div>
                        </md-card-content>
                        <md-card-actions>
                            <div class="mute left vcenter" style="align-items:center">
                                <md-icon class="footer-icons">person</md-icon>
                                &nbsp;{{ r.user_name }}&nbsp;&nbsp;
                                <md-icon class="footer-icons" style="margin-left:10px">access_time</md-icon>
                                &nbsp;{{ r.createdAt | date }}
                            </div>
                        </md-card-actions>
                    </md-card>
                </template>
            </md-card>
        </div>
        <br />
        <md-dialog ref="reviewModal">
            <md-dialog-title>Rate & Review</md-dialog-title>
            <md-dialog-content>
                <section class="sb">
                    <form autocomplete="false" class="sb" name="myForm" novalidate>
                        <div class="row">
                            <md-rating-bar :md-empty-icon="'star_border'" :md-icon-size="1" class="md-warn" v-model="ratingCount"></md-rating-bar>
                        </div>
                        <md-input-container class="flex">
                            <md-textarea name="message" placeholder="Message" v-model="message"></md-textarea>
                        </md-input-container>
                        <div class="error">{{ error.message }}</div>
                    </form>
                </section>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button @click="closeModal()">Cancel</md-button>
                <md-button @click="submitReview({ rating: ratingCount, message })" class="md-primary">Save</md-button>
            </md-dialog-actions>
        </md-dialog>

        <confirm @close="deleteConfirmed" ref="deleteConfirm"></confirm>
        <br />
    </div>
</template>
<script>
import Confirm from "@/components/modal/Confirm";
import { reviewSettings } from "@/config";
import { mapGetters, mapMutations } from "vuex";

export default {
    props: ["product"],
    data() {
        return {
            reviews: [],
            ratingCount: 0,
            rating: null,
            reviewSettings,
            starsArray: [],
            message: null,
            error: {}
        };
    },
    components: {
        Confirm
    },
    created() {
        this.getReviews();
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        })
    },
    methods: {
        ...mapMutations(["setError", "setMessage"]),
        async getReviews() {
            if (!this.product._id) return;
            try {
                let r = await this.$store.dispatch("crud/get", {
                    api: "reviews",
                    params: {
                        active: true,
                        pid: this.product._id,
                        pageSize: 5,
                        sort: "-createdAt"
                    }
                });
                this.publishRatings(r.data || r);
                this.reviews = r.data || r;
            } catch (err) {
                this.err(err);
            }
        },
        publishRatings(r) {
            let vm = this;
            let reviewCount = 0;
            let rating = {
                r5: 0,
                r4: 0,
                r3: 0,
                r2: 0,
                r1: 0,
                count: 0,
                total: 0,
                avg: 0
            };
            r.forEach(function(i) {
                if (i.message) reviewCount++;
                if (i.rating) rating.count++;
                if (i.rating) rating.total = rating.total + i.rating;
                if (i.rating == 5) rating.r5++;
                if (i.rating == 4) rating.r4++;
                if (i.rating == 3) rating.r3++;
                if (i.rating == 2) rating.r2++;
                if (i.rating == 1) rating.r1++;
            }, this);
            this.reviewCount = reviewCount;
            rating.avg = Math.round((rating.total / rating.count) * 10) / 10;
            this.rating = rating;
        },
        deleteReview(review) {
            this.singleReview = review; // Required for deleteConfirmed()
            this.$refs.deleteConfirm.open("Would you like to delete the review ?", null, review);
        },
        async deleteConfirmed(close) {
            if (close === "ok") {
                try {
                    await this.$store.dispatch("crud/delete", { api: "reviews/" + this.singleReview._id });
                    this.getReviews();
                } catch (err) {
                    this.err("Error while saving your review.");
                }
            }
        },
        // myReview(review) {
        //   return this.hasRole('manager');
        //   // return true;
        // },
        reviewForm() {
            if (!this.user || !this.user.email) {
                // this.$refs["loginModal"].open();
                this.$router.push("/account/login");
            } else {
                this.review();
            }
        },
        loginSuccess() {
            this.review();
        },
        review() {
            this.$refs["reviewModal"].open();
        },
        async submitReview(data) {
            if (!data.rating) {
                this.message = "Please rate the item from a scale of 1-5";
                return;
            }
            this.$refs["reviewModal"].close();

            data.pid = this.product._id;
            data.pname = this.product.name;
            data.pslug = this.product.slug;

            data.vendor_id = this.product.vendor_id;
            data.vendor_name = this.product.vendor_name;
            data.vendor_email = this.product.vendor_email;

            data.user_id = this.user._id;
            data.user_name = this.user.name;
            data.user_email = this.user.email;

            await this.$store.dispatch("crud/post", { api: "reviews", data: data });
            this.getReviews();
            if (this.reviewSettings.moderate) {
                this.popMessage("Your review is under moderation. Will be visible to public after approval.");
            }
        },
        closeModal() {
            this.$refs["reviewModal"].close();
        },
        popMessage(msg) {
            this.setMessage(msg);
        },
        err(err) {
            this.setError(err.err);
        }
    }
};
</script>

<style lang="less">
.reviews {
    .reviews-header {
        padding: 10px;
    }
    .sb {
        display: flex;
    }
    .flex {
        flex: 1;
    }
    .rating-signal {
        flex-direction: column;
    }

    .footer-icons {
        font-size: 18px;
        width: 18px;
        height: 18px;
    }

    .w100 {
        width: 88%;
    }

    .ml10 {
        margin-left: 15px;
    }

    .md-progress {
        margin: 0 10px 0 5px;
    }

    .mb10 {
        margin-bottom: 10px;
    }

    .mute {
        color: #aaa;
    }

    .close {
        position: absolute !important;
        right: 0px;
        top: 3px;
        width: 20px;
        min-width: 22px;
    }

    .reviews-header .total-rating {
        margin: 10px 0px 10px 10px;
        width: 80px;
    }

    .rating-signal {
        display: flex;
    }

    .rating-signal md-progress-linear {
        width: 150px;
        margin: 0 20px;
    }

    .reviews-header .total {
        font-size: 2em;
        margin-bottom: 2px;
    }

    md-card-header-text .md-title {
        font-weight: bold;
    }

    .rating-button {
        font-size: 1.1em;
        background-color: limegreen;
        color: #fff;
        width: 49px;
        border-radius: 4px;
        padding: 0 6px;
    }

    .rating-button ng-md-icon {
        margin-top: -3px;
    }

    @media (max-width: 600px) {
        .reviews-header {
            flex-direction: column;
        }
    }
}
</style>
