<template>
    <div class="product-detail">
        <div class="product-container" v-if="product && product.variants">
            <div class="main-image-container">
                <div class="product-images">
                    <div :class="{ active: mainImage === image }" :key="ix" @click="mainImage = image" style="cursor:pointer" v-for="(image, ix) in product.images">
                        <img :src="image | googleimage" alt />
                    </div>
                </div>

                <div class="main-image">
                    <img :alt="product.name" :src="mainImage | googleimage" :title="product.name + ' ' + variant.name" />
                </div>
            </div>
            <div class="product-description">
                <h2 class="name">{{ product.name }}</h2>
                <md-divider></md-divider>
                <p class="price">
                    <label for>Price:</label>
                    <b>{{ variant.price | currency }}</b>
                    <span v-if="product.rental">/ {{ product.rentalInterval | intervalName }}</span>
                </p>
                <p class="center" v-if="hasRole('vendor')">
                    <label for>Stock:</label>
                    <b>{{ product.unlimited ? "Unlimited" : variant.stock }}</b>
                </p>
                <p class="center" v-if="hasRole('vendor')">
                    <label for>Maintenance / Grace Period:</label>
                    <b>{{ product.prepareDays }}</b>
                </p>
                <p v-if="product.rental">
                    <label for>Rental Deposit:</label>
                    <b>{{ product.rentalDeposit | percent }}</b>
                </p>
                <p class="center" v-if="product.rental && product.rentalInterval == 'hourly' && product.rentalMinDays">{{ product.rentalMinDays }} Hours Minimum Booking</p>
                <p class="center" v-if="product.rental && product.rentalInterval == 'daily' && product.rentalMinDays">{{ product.rentalMinDays }} Days Minimum Booking</p>
                <p>
                    <label>Seller:</label>
                    <router-link :to="'/' + product.vendor_name">{{ product.vendor_name }}</router-link>
                    <span>&lt;Click to see reviews</span>
                </p>
                <div class="left" v-if="product.category">
                    <label>Category:</label>
                    <template v-for="(c, index) in productCategories">
                        <router-link :key="c.slug" :to="'/category/' + c.slug">{{ c.name }}</router-link>
                        <span :key="index" v-if="index < productCategories.length - 1">></span>
                    </template>
                </div>

                <div class="left" v-if="variant.size">
                    <label>Size:</label>
                    <b>{{ variant.size }}</b>
                </div>
                <div class="left" v-if="product.brand">
                    <label>Brand:</label>
                    <b>{{ product.brand.name }}</b>
                </div>

                <div style="margin-bottom:10px;" v-if="product.keyFeatures && product.keyFeatures.length > 0">
                    <label>Key Features:</label>
                    <md-table>
                        <md-table-body>
                            <md-table-row :key="ix" v-for="(k, ix) in product.keyFeatures">
                                <md-table-cell>{{ k.key }}</md-table-cell>
                                <md-table-cell>{{ k.val }}</md-table-cell>
                            </md-table-row>
                        </md-table-body>
                    </md-table>
                </div>
                <div style="margin-bottom:10px;" v-if="product.features && product.features.length > 0">
                    <label>Features:</label>
                    <md-table>
                        <md-table-body>
                            <md-table-row :key="ix" v-for="(k, ix) in product.features">
                                <md-table-cell>{{ k.key }}</md-table-cell>
                                <md-table-cell>{{ k.val }}</md-table-cell>
                            </md-table-row>
                        </md-table-body>
                    </md-table>
                </div>
                <div style="margin-bottom:10px;" v-if="product.description">
                    <label>Description:</label>
                    <div v-html="product.description"></div>
                </div>
                <div style="margin-bottom:10px;" v-if="product.type == 'Package' && variant && variant.items">
                    <label>Package Includes:</label>
                    <md-table>
                        <md-table-header>
                            <md-table-row>
                                <md-table-cell>Name</md-table-cell>
                                <md-table-cell>Quantity</md-table-cell>
                            </md-table-row>
                        </md-table-header>
                        <md-table-body>
                            <md-table-row :key="ix" v-for="(i, ix) in variant.items">
                                <md-table-cell>
                                    <router-link :to="'/' + i.slug + '/' + i._id">{{ i.name }} / {{ i.vname }}</router-link>
                                </md-table-cell>
                                <md-table-cell>{{ i.quantity }}</md-table-cell>
                            </md-table-row>
                        </md-table-body>
                    </md-table>
                </div>
                <div style="margin-bottom:10px;">
                    <label>Variants:</label>
                    <b>{{ variant.name }}</b>
                    <div class="product-variants">
                        <div :class="{ active: v == variant }" :key="ix" @click="changeIndex(ix)" class="variant-select" v-for="(v, ix) in product.variants">
                            <img :alt="product.name" :src="v.image | googleimage" :title="v.name" />
                        </div>
                    </div>
                </div>
                <div class="left">
                    <cart-date-buttons :product="product" :variant="variant"></cart-date-buttons>
                </div>
                <div class="left">
                    <product-add-on-items :product="product" @change="handleAddOnItemsChange"></product-add-on-items>
                </div>
                <div class="left" style="margin-bottom:10px">
                    <cart-buttons :product="product" :variant="variant" ref="cartButton"></cart-buttons>
                    <wish-button :product="product" :variant="variant"></wish-button>
                </div>
                <product-reviews :product="product"></product-reviews>
                <q-a-product :id="id"></q-a-product>
            </div>
            <product-referral :product="product"></product-referral>
        </div>
        <md-card v-if="!product && !loading">
            <h3 style="text-align:center">The page does not exist anymore</h3>
            <router-link class="md-primary md-raised" tag="md-button" to="/"> <md-icon>shop</md-icon>Find More </router-link>
        </md-card>
    </div>
</template>

<script>
import ProductReviews from "@/components/Product/ProductReviews";
import ProductReferral from "@/components/Product/ProductReferral";
import WishButton from "@/components/Wish/WishButton";
import CartButtons from "@/components/Cart/CartButtons";
import CartDateButtons from "@/components/Cart/CartDateButtons";
import ProductAddOnItems from "@/components/Product/ProductAddOnItems";
import QAProduct from "@/components/QA/QAProduct.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
    props: ["slug", "id"],
    components: {
        ProductReviews,
        WishButton,
        CartButtons,
        CartDateButtons,
        ProductAddOnItems,
        ProductReferral,
        QAProduct
    },
    data() {
        return {
            loading: true,
            mainImage: "",
            product: null,
            selectedIndex: 0
        };
    },
    computed: {
        ...mapGetters({
            hasRole: "user/hasRole",
            categories: "category/categories",
            getCategoryParentById: "category/getParentsById"
        }),
        variant() {
            var variant = this.product.variants[this.selectedIndex];
            this.mainImage = variant.image;
            return variant;
        },
        productCategories() {
            if (this.product && this.product.category) {
                let parents = this.getCategoryParentById(this.product.category);
                let child = this.categories.find(c => c._id == this.product.category);
                console.info(this.categories);
                console.info(parents, child);
                return [...parents, child].filter(a => a);
            }
        }
    },
    watch: {
        id() {
            this.init();
        }
    },
    methods: {
        changeIndex(i) {
            this.selectedIndex = i;
        },
        handleAddOnItemsChange() {
            this.$refs["cartButton"].handleAddOnItemsChange();
        },
        setSeo() {
            if (this.product) {
                let descriptionDiv = document.createElement("div");
                descriptionDiv.innerHTML = this.product.description || "";
                let descriptionText = descriptionDiv.innerText.replace(/\n\r|\r\n|\n/g, " ");
                let description = descriptionText.match(/(.{50}.*?)[^a-zA-Z ]/);
                description = description && description[1];
                description = description || descriptionText.substring(0, 50);
                this.$store.dispatch("seo/set", {
                    headline1: this.product.name,
                    description: description
                });
            }
        },
        async load() {
            this.product = null;
            this.loading = true;
            let result = await this.$store.dispatch("crud/get", {
                api: `products/${this.id}`
            });
            this.product = result;
            if (this.product.description) {
                // replace blank space
                this.product.description.replace(/<p><br><\/p>/g, "");
            }
            this.loading = false;
        },

        async init() {
            // document.documentElement.scrollTop = 0;
            await this.$store.dispatch("category/loadCategories");
            await this.load();
            this.setSeo();
            this.$emit("load", this.product);
        }
    },
    async created() {
        this.init();
    },
    async destroyed() {
        this.$store.dispatch("seo/setDefault");
    }
};
</script>
<style lang="less">
.product-detail {
    .left {
        display: flex;
        flex-wrap: wrap;
        > * {
            margin-right: 5px;
        }
    }

    .md-divider {
        margin: 5px 0;
    }

    .product-container {
        display: flex;
        flex-wrap: wrap;
    }
    .main-image-container {
        width: 650px;
        height: 570px;
        margin: 0 auto;
        display: flex;
        padding: 0 20px;

        .main-image {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            width: 550px;
            height: 550px;
            padding: 25px 0;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .product-images {
            width: 60px;
            margin-right: 10px;
            height: 550px;
            overflow: auto;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                overflow: hidden;
                margin: 7px auto;
                outline: 0;
                border: 1px solid #a2a6ac;
                box-sizing: border-box;

                img {
                    height: 100%;
                }
                &.active {
                    border-color: #e77600;
                    box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
                }
            }

            &::-webkit-scrollbar {
                width: 4px;
                height: 5px;
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: rgba(0, 0, 0, 0.2);
                cursor: pointer;
            }
            &::-webkit-scrollbar-track {
                border-radius: 0;
                /* background: rgba(0, 0, 0, 0.1); */
                cursor: pointer;
            }
        }
    }
    .product-description {
        flex: 1;
        margin: 0 10px 10px 10px;
        padding: 10px;
        label {
            color: #999;
        }
        p {
            margin: 0;
        }
        i {
            margin: 0;
        }
        pre {
            margin: 0;
        }
    }

    .product-variants {
        display: flex;

        .variant-select {
            margin-right: 10px;
            border: 1px solid transparent;
            cursor: pointer;

            &.active {
                border: 1px solid orange;
            }
        }

        img {
            height: 50px;
        }
    }

    @media (max-width: 761px) {
        .product-description {
            margin: 0;
            padding: 10px;
            width: 100%;
            box-sizing: border-box;

            .cart-date-buttons {
                flex-direction: column;
            }
        }
        .main-image-container {
            width: 100%;
            // height: 300px;
            height: auto;
            flex-direction: column-reverse;

            .main-image {
                padding: 5px 0 10px;
                width: 100%;
                // height: 300px;
                height: auto;
            }

            .product-images {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;

                div {
                    flex-shrink: 0;
                    width: 50px;
                    height: 50px;
                    margin: 0 10px 0 0;
                }
            }
        }

        .side-thumb {
            order: 1;
            display: flex;
        }
    }
}

.vendorMode {
    .product-detail {
        a {
            color: #38f;
        }
    }
}
</style>
