<template>
    <div class="product-referral" v-if="referrals.length">
        <h3 class="title">Customers also booked these items</h3>
        <div class="referral-container">
            <product-card v-for="(r,ix) in referrals" :key="ix" :product="r"></product-card>
        </div>
    </div>
</template>
<script>
import ProductCard from "@/components/Product/ProductCard";
import { mapGetters } from "vuex";
export default {
    props: ["product"],
    components: {
        ProductCard
    },
    data() {
        return {
            referrals: []
        };
    },
    computed: {
        ...mapGetters({
            vendorMode: "vendorMode"
        })
    },
    watch: {
        product() {
            this.getProductReferrals();
        }
    },
    methods: {
        async getProductReferrals() {
            if (this.product && this.product._id) {
                let result = await this.$store.dispatch("crud/get", {
                    api: `products/referral/${this.product._id}`,
                    params: {
                        vendor: this.vendorMode && this.vendorMode._id
                    }
                });
                this.referrals = result;
            }
        }
    },
    mounted() {
        this.getProductReferrals();
    }
};
</script>
<style lang="less">
.product-referral {
    width: 100%;
    .title {
        text-align: center;
    }
    .referral-container {
        margin: auto;
        display: flex;
        overflow: auto;
        justify-content: center;

        .product-card {
            width: 170px;
            flex: 0 0 auto;

            .md-card-header {
                padding-top: 5px;
                .md-title {
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                    line-height: 18px;
                    max-height: 34px;
                }
                .price {
                    margin: 5px;
                    font-size: 16px;
                }
                .vendor__name {
                    display: none;
                }
            }
        }
    }
}
@media (max-width: 600px) {
    .product-referral {
        .referral-container {
            justify-content: flex-start;
        }
        .product-card {
        }
    }
}
</style>

